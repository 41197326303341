import { StopOutlined } from "@ant-design/icons"

const PRODUCTION = process.env.NODE_ENV === "production"

export const DEFAULT_LANGUAGE = "en"
const PRODUCTION_LANGUAGES = ["da-dk"]
const BETA_LANGUAGES = ["pl-pl", "da-dk"]

/** 🇬🇧 , 🇩🇰 , 🇵🇱  */
export const SUPPORTED_LANGUAGES = Object.freeze(
  [DEFAULT_LANGUAGE].concat(PRODUCTION ? PRODUCTION_LANGUAGES : BETA_LANGUAGES)
)

export const FRONTPAGE = "processes"

/** https://caniuse.com/css-sticky */
export const POSITION_STICKY_SUPPORTED = (() => {
  const el = document.createElement("a")
  const mStyle = el.style
  mStyle.cssText = "position:sticky;position:-webkit-sticky;position:-ms-sticky;"
  return mStyle.position.indexOf("sticky") !== -1
})()

export const EMPTY_OPF_ITEM = Object.freeze({ id: null, slug: null, opfId: null, level: 1 })
export const EMPTY_HTML = "<p></p>"
export const NULL_DATE = "0001-01-01T00:00:00"

/** Application initialization status types, used to identify boot progress. */
export const INIT_STATUS = Object.freeze({
  FAILED: "failed",
  FINISHED: "finished",
  WAITING: "waiting",
  LOADING: "loading",
  NO_CLAIMS: "noClaims",
})

/** PolicyHub Hierarchy trees used in ApplicationContext */
export const TREES = Object.freeze({
  /** Hierarchy for Documents with Policies and Standards */
  POLICIES_STANDARDS: "policies-standards",
  /** Hierarchy for PolicyHub Items */
  PROCESSES: "processes",
  /** Hierarchy for Documents with Commercial Legal */
  COMMERCIAL_LEGAL: "commercial-legal",
  /** Hierarchy for Certifications */
  CERTIFICATIONS: "certifications",
})

/** Actions that can be performed on PolicyHub data */
export const ACTIONS = Object.freeze({
  ADD: "add",
  ADD_PROCEDURE: "add procedure",
  EDIT: "edit",
  ARCHIVE: "archive",
  REMOVE_FROM_CONFIGURATOR: "removeFromConfigurator",
  REFINER: "refiner",
  CREATE: "create",
  LOCK: "lock",
  MOVE: "move",
  UNLOCK: "unlock",
  TRANSLATE: "translate",
  UPDATE_OWNERS: "updateOwner",
  UPDATE_ORDER: "updateOrder",
  ADD_FAVORITE: "addToFavorites",
  REMOVE_FAVORITE: "removeFromFavorites",
  UPLOAD_DOCUMENT: "uploadDocument",
  VIEWABLE_BY: "viewableBy",
  // CREATE_VARIANT: "createVariant",
  // CREATE_CATEGORY_VARIANT: "createCategoryVariant",
  // EDIT_VARIANT: "editVariant",
  // ARCHIVE_VARIANT: "archiveVariant",
  // LOCK_VARIANT: "lockVariant",
  // UNLOCK_VARIANT: "unlockVariant",
  // TRANSLATE_VARIANT: "translateVariant",
  ADD_STEP: "addStep",
  EDIT_STEP: "editStep",
  DELETE_STEP: "deleteStep",
  // ADD_VARIANT_STEP: "addVariantStep",
  // EDIT_VARIANT_STEP: "editVariantStep",
  // DELETE_VARIANT_STEP: "deleteVariantStep",
  UNDEFINED_ACTION: "undefinedAction",
  ADD_DOCUMENT_CATEGORY: "addDocumentCategory",
  ADD_DOCUMENT_SUBCATEGORY: "addDocumentSubcategory",
  EDIT_TOOLS: "editTools",
  TRANSLATE_TOOLS: "translateTools",
  EDIT_RELATIONS: "editRelations",
  UPLOAD_PDF: "uploadPdf",
  CONFIGURE_PROCESSES: "configureProcesses",
  CONFIGURE_POLICIES_STANDARS: "configurePoliciesStandards",
  CONFIGURE_COMMERCIAL_LEGAL: "configureCommercialLegal",
  ADD_CHAPTER: "addChapter",
  // ADD_CHAPTER_VARIANT: "addVariantChapter",
  SET_NOTIFICATION: "setNotification",
  SET_PUBLISH_VERSION: "setPublishVersion",
  PRINT: "print",
  PREPUBLISH: "prePublish",
  PUBLISH: "publish",
  EDIT_LOCAL_AMENDMENT: "editLocalAmendments",
})

export const ANALYTICS = Object.freeze({
  EVENT_VPV: "vpv",
  EVENT_ACTION: "action",
  EVENT_CATEGORY_TOP_LEVEL_EVENTS: "top level events",
  EVENT_CATEGORY_SECOND_LEVEL_EVENTS: "second level events",
  INTERACTION_EVENT: "interactionevent",
  COMPARE_VIEW: "new compare view",
  ITEM_ACTIONS: "item actions",
  POLICY_STANDARD_ACTIONS: "policiesStandards",
  ACCESSING_RESOURCES: "accessing resources",
  START_ADD: "Add - Start",
  END_ADD: "Add - Done",
  START_EDIT: "Edit - Start",
  END_EDIT: "Edit - Done",
  // START_CREATE_VARIANT: "Create Variant - Start",
  // END_CREATE_VARIANT: "Create Variant - Done",
})

export const ROLES = Object.freeze({
  SYS_ADMIN: "System Administrator",
  GLOBAL_PROCESS_OWNER: "Global Process Owner",
  GLOBAL_POLICIES_STANDARDS_OWNER: "Global Policies-Standards Owner",
  GLOBAL_ACCOUNT_PROCESS_OWNER: "Global Account Process Owner",
  COUNTRY_PROCESS_OWNER: "Country Process Owner",
  ACCOUNT_MANAGER: "Account Manager",
  SITE_MANAGER: "Site Manager",
  LOCAL_MANAGER: "Local Manager",
  SERVICE_EMPLOYEE: "Service Employees",
  ANONYMOUS: "Anonymous",
})

export const OPF_ITEM_TYPES = Object.freeze({
  PROCEDURE_ELEMENT: "procedure element",
  PROCEDURE_CATEGORY: "procedure category",
  PROCEDURE: "procedure",
  PROCEDURE_STEP: "procedure step",
  PROCESS: "process",
  PROCESS_ELEMENT: "process element",
  PROCESS_CATEGORY: "process category",
  CONFIGURATOR: "configurator",
  WORD_IMPORT: "word import",
})

export const OPF_SCOPES = Object.freeze({
  GLOBAL: "global",
  LOCAL: "local",
  // GLOBAL_VARIANT: "global variant",
  // LOCAL_VARIANT: "local variant",
})

/** Configuration types for PolicyHub */
export const OPF_CONFIGURATION_TYPES = Object.freeze({
  ACCOUNT: "account",
  GLOBAL_ACCOUNT: "globalaccount",
  COUNTRY: "country",
  COUNTRY_ACCOUNT: "countryaccount",
  SITE: "site"
})

export const OPF_DOCUMENT_TYPES = Object.freeze({
  DOCUMENT_CATEGORY: "document category",
  DOCUMENT_SUBCATEGORY: "document subcategory",
  DOCUMENT_CATEGORY_VERSION: "document category version",
  DOCUMENT_SUBCATEGORY_VERSION: "document subcategory version",
  DOCUMENT: "document",
  // DOCUMENT_VARIANT: "document variant",
  POLICY: "policy",
  POLICY_VERSION: "policy version",
  STANDARD: "standard",
  STANDARD_VERSION: "standard version",
  POLICIES_STANDARDS_CATEGORY: "policies-standards category",
  POLICIES_STANDARDS_SUBCATEGORY: "policies-standards subcategory",
  POLICIES_STANDARDS_CATEGORY_VERSION: "policies-standards category version",
  POLICIES_STANDARDS_SUBCATEGORY_VERSION: "policies-standards subcategory version",
  CERTIFICATION: 'certification',
  CERTIFICATION_CATEGORY: "certification category",
  CERTIFICATION_SUBCATEGORY: "certification subcategory",
})

type Classification = {
  label: string
  description: string
  longdescription: string
  icon: React.ReactNode
  viewlabel: string
}

export const CLASSIFICATIONS: Record<number, Classification> = {
  0: {
    label: "common.classification.selectone",
    description: "common.classification.selectone.description",
    longdescription: "common.classification.selectone.longdescription",
    icon: null,
    viewlabel: "common.classification.empty",
  },
  1: {
    label: "common.confidential",
    description: "common.confidential.description",
    longdescription: "common.confidential.longdescription",
    icon: <StopOutlined />,
    viewlabel: "common.confidential",
  },
  2: {
    label: "common.restrictedExternal",
    description: "common.restrictedExternal.description",
    longdescription: "common.restrictedExternal.longdescription",
    icon: <StopOutlined />,
    viewlabel: "common.restrictedExternal",
  },
  3: {
    label: "common.restrictedInternal",
    description: "common.restrictedInternal.description",
    longdescription: "common.restrictedInternal.longdescription",
    icon: <StopOutlined />,
    viewlabel: "common.restrictedInternal",
  },
  4: {
    label: "common.unrestricted",
    description: "common.unrestricted.description",
    longdescription: "common.unrestricted.longdescription",
    icon: null,
    viewlabel: "common.unrestricted",
  },
}

export type ReasonForChange = {
  label: string
}

export const REASON_FOR_CHANGE_OPTIONS: Record<number, ReasonForChange> = {
  1: { label: "common.newDocument" },
  2: { label: "common.periodicalReview" },
  3: { label: "common.requirementChangedOrAdded" },
  4: { label: "common.processChanged" },
  5: { label: "common.accountabilityChanged" },
  6: { label: "common.significantChangeInData" },
  7: { label: "common.newLegalRequirement" },
  8: { label: "common.auditRecommendations" },
}

export type ChangedDocument = {
  label: string, radiovalue: string, inchangelog: boolean, innotifications : boolean
}

export const CHANGED_DOCUMENT_OPTIONS: Record<number, ChangedDocument> = {
  1: { label: "form.notification.changeTo.document", radiovalue: "document", inchangelog: true, innotifications: true },
  2: { label: "form.notification.changeTo.appendix", radiovalue: "appendix", inchangelog: true, innotifications: true },
  3: { label: "form.notification.changeTo.supportingDocument", radiovalue: "supportingDocument",inchangelog: false, innotifications: true },
  4: { label: "form.notification.changeTo.correction", radiovalue: "correction", inchangelog: false, innotifications: false },
}
