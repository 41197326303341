import { OPF_DOCUMENT_TYPES, OPF_ITEM_TYPES } from "../globals"

import { camelCase } from "lodash"

const {
  PROCEDURE_ELEMENT,
  PROCEDURE,
  PROCESS,
  PROCESS_ELEMENT,
  PROCESS_CATEGORY,
  PROCEDURE_CATEGORY,
} = OPF_ITEM_TYPES

const {
  POLICY,
  STANDARD,
  POLICIES_STANDARDS_CATEGORY,
  POLICIES_STANDARDS_SUBCATEGORY,
} = OPF_DOCUMENT_TYPES

/** returns the item types that the given type can contain */
export const getItemTypeChildTypes = (type) => {
  switch (type) {
    case PROCEDURE:
      return [PROCEDURE_ELEMENT] // aka. procedure step
    case PROCESS:
      return [PROCESS_ELEMENT]
    case PROCESS_ELEMENT:
    case PROCEDURE_CATEGORY:
      return [PROCEDURE_CATEGORY]
    case PROCESS_CATEGORY:
      return [PROCESS, PROCESS_CATEGORY]
    case POLICIES_STANDARDS_CATEGORY:
    case POLICIES_STANDARDS_SUBCATEGORY:
      return [POLICY, STANDARD]
    default:
      return []
  }
}

export const isItemType = (value) => Object.values(OPF_ITEM_TYPES).includes(value)

/** Get corrosponding format.js id key for a given PolicyHub item type to be used in <FormattedMessage /> */
/** export const getTypeIntlKey = (type) => "common." + camelCase(type) */

export const getTypeIntlKey = (type) => {
  switch (type) {
    case PROCESS_ELEMENT:
    case PROCEDURE_CATEGORY:
      return "common." + camelCase(PROCEDURE_CATEGORY)
    default:
      return "common." + camelCase(type)
  }
}

/** Get format.js id key for select type guiding tooltip */
export const getTypeHelpMeDecideTooltip = (type) =>
  "guide.tooltip.helpMeDecideType." + camelCase(type)

/**
 * Groups a collection of PolicyHub items by their item type
 * @example
 * ```js
    groupItemsByType([]) ->
      {
      'process': [],
      'procedure category': [],
      'procedure: [],
      ...
      }
  ```
 */
export const groupItemsByType = (items) =>
  Object.values(OPF_ITEM_TYPES).reduce(
    (accum, type) => [...accum, { type, children: items.filter((item) => item.type === type) }],
    []
  )

/** Filter function for excluding all PolicyHub items except those with the types: Process, Procedure Category and Process Category */
export const categoryFilter = (item) =>
  [PROCESS, PROCEDURE_CATEGORY, PROCESS_CATEGORY].includes(item.type)

/** Filter function for excluding steps from a collection of PolicyHub items */
export const excludeSteps = (item) => item.type !== PROCEDURE_ELEMENT
